<template>
  <div>
    <el-dialog
      title="选择广告"
      :visible="editPageShow"
      width="600px"
      @close="close"
      @open="openInit"
    >
      <div class="selectList">
        <el-checkbox-group
          v-model="checkedList"
          :min="1"
        >
          <el-checkbox
            v-for="item in list"
            :label="item.id"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}
            <p>{{ resultObj[item.id] }}</p>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="close"
          size="mini"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="slelectPageShow = true"
          size="mini"
          >{{ num ? '重 试' : '修改主页' }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="批量修改广告主页"
      :visible="slelectPageShow"
      width="600px"
      @close="slelectPageShow = false"
      @open="openInit"
    >
      <div v-if="slelectPageShow">
        <el-form
          :model="pageForm"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
          :rules="rules"
        >
          <el-form-item
            label="选择主页："
            prop="page_id"
          >
            <el-select
              v-model="pageForm.page_id"
              placeholder="请选择主页"
              style="width: 90%"
              @change="getIns"
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in pageList"
                :key="index"
                :disabled="!item.is_published"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Instagram账号："
            prop="ins_id"
          >
            <el-select
              v-model="pageForm.ins_id"
              placeholder="请选择Instagram账号"
              style="width: 90%"
            >
              <el-option
                :label="item.username"
                :value="item.id"
                v-for="(item, index) in pageInstagramsList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click.stop="slelectPageShow = false"
          size="mini"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click.stop="sure"
          size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { updateAdPage } from '@/api/adManagement';
import { accountPages, pageInstagrams } from '@/api/creatAd.js';
import axios from 'axios';
export default {
  props: ['editPageShow', 'nameLevel', 'list'],
  data() {
    return {
      pageForm: {
        page_id: '',
        ins_id: null,
      },
      accountId: this.$store.getters.accountId,
      pageList: [],
      loadPage: false,
      pixelList: [],
      pageInstagramsList: [],
      rules: {
        page_id: [{ required: true, message: '请先选择主页', trigger: 'change' }],
        ins_id: [{ required: false, message: '请先选择Instagram账号', trigger: 'change' }],
      },
      slelectPageShow: false,
      num: 0,
      resultObj: {},
    };
  },
  watch: {
    list: {
      handler() {
        this.checkedList = this.list.map((v) => v.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    close() {
      (this.num = 0), (this.resultObj = {});
      this.$emit('update:editPageShow', false);
    },
    openInit() {
      this.checkedList = this.list.map((v) => v.id);
      console.log(this.checkedList);
      this.$showLoading();
      this.getPixelList();
    },
    // selectAds(v){
    //     console.log(v);
    //     this.checkedList = v;
    // },
    getPixelList(v) {
      this.loadPage = true;
      accountPages({ accountId: this.accountId }).then((res) => {
        // console.log(res);
        this.loadPage = false;
        this.$hideLoading();
        this.pageList = res.data;
        if (v) {
          this.$message('更新成功');
        }
      });
    },
    getIns(v) {
      this.pageForm.ins_id = null;
      pageInstagrams({ pageId: v, adAccountId: this.accountId }).then((res) => {
        this.$hideLoading();
        this.pageInstagramsList = res.data;
      });
    },
    updatePage(id) {
      return updateAdPage({ ad_id: id, ...this.pageForm, account_id: this.accountId });
    },
    sure() {
      this.$refs.ruleForm.validate((v) => {
        if (v) {
          let ids = this.list.map((v) => v.id);
          let requestArr = ids.map((v) => {
            return this.updatePage(v);
          });
          this.$showLoading();
          axios.all(requestArr).then(
            axios.spread((...arg) => {
              this.num = 0;
              this.resultObj = {};
              let failIdList = [];
              this.$hideLoading();
              this.slelectPageShow = false;
              Array.from([...arg]).forEach((res, index) => {
                //这里可以查看每个请求的返回数据 console.log(element)
                this.resultObj[res.data.ad_id] = res.data.fail_reason;
                if (res.data.execute_status !== 'success') {
                  this.num += 1;
                  failIdList.push(res.data.ad_id);
                }
              });
              console.log(this.resultObj);
              console.log(this.num);
              if (this.num) {
                this.checkedList = [...failIdList];
              } else {
                this.$message({
                  type: 'success',
                  message: '更新成功',
                });
                this.close();
              }
            }),
          );
        }
      });
    },
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  height: 100%;
  overflow-y: auto;
}
</style>
<style lang="scss" scoped>
.el-checkbox ::v-deep .el-checkbox__label {
  white-space: normal;
}

.selectList .el-checkbox ::v-deep .el-checkbox__label p {
  font-size: 12px;
  color: #f00;
  margin-left: 10px;
}
</style>
